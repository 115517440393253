.body {
  background: var(--color-background);
  overflow-x: hidden;
}

.App {
  margin: 0px;
  background: var(--color-background);
  color: var(--color-text-normal);
  text-align: center;
  min-height: 100vh;
}

.header {
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  top: 0;
  height: 60px;
  width: 100%;
  background-color: var(--color-nav-elements-header-bg);
  backdrop-filter: blur(8px);
  z-index: var(--zindex-main-layout);
}

.tableContainer {
  background-color: var(--color-background);
  position: absolute;
  top: 60px;
  height: calc(100vh - 60px);
  width: 100%;
  overflow-y: auto;
}