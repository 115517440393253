.tableWrapper {
    width: 100%;
}

.tableBody {
    min-width: 100%;
    background-color: var(--color-background);
    border: 1px solid var(--color-table-border);
    border-spacing: 0;
    text-align: left;
    font-size: 14px;
}

.tableHeader {
    top: 0;
    position: sticky;
    background-color: var(--color-table-header);
    text-transform: uppercase;
}

.tableHeaderCell {
    padding: 6px;
    border-bottom: 1px solid var(--color-table-border);
    border-right: 1px solid var(--color-table-border);
    white-space: nowrap;
}

.tableCell {
    padding: 3px 6px;
    vertical-align: middle;
    border-bottom: 1px solid var(--color-table-border-muted);
    border-right: 1px solid var(--color-table-border-muted);
}

.emptyChannelSlow {
    background-color: var(--color-table-row-disabled);
}

.tableRow:hover {
    background-color: var(--color-table-row-hover);
}

.cellAlignLeft {
    text-align: left;
}

.cellAlignCenter {
    text-align: center;
}

.cellAlignRight {
    text-align: right;
}

.cellNoWrap {
    text-wrap: nowrap;
}

.cellTabularNums {
    font-variant-numeric: tabular-nums;
}

.columnRecordNumber {
    color: var(--color-text-muted);
    font-family: "Noto Sans Mono", monospace;
    font-size: 1.2em;
    font-weight: 800;
    font-variant-numeric: tabular-nums;
}
