.button {
    color: var(--color-text-normal);
    height: 30px;
    width: 30px;        
    background-color: transparent;
    border: 0;
    border-radius: 9999px;
    padding: 0;
    margin: 0;
    cursor: pointer;
}

.button svg {
    margin-top: 2px;
}

.button:hover {
    color: var(--color-text-selected);
}

.popover {
    padding: 10px;
    background-color: var(--color-popover-background);
    border-radius: 8px;
    z-index: var(--zindex-modals);
}