.radioItem {
    display: block;
    border: 1px solid gray;
    border-radius: 8px;
    width: 200px;
    margin: 10px;
    padding: 10px;
    cursor: pointer;
    box-sizing: border-box;
}

.radioItem:hover {
    border: 3px solid darkgrey;
}

.isSelected {
    border: 3px solid darkgreen;
}

.radioItem img {
    width: 100%;
}

.radioPickerList {
    display: flex;
    flex-direction: row;
}