.listboxButton {
    margin-right: 24px;
    text-align: left;
    white-space: nowrap;
    width: 100%;
    border: 0;
    /* padding: 4px 10px; */
    background-color: transparent;
    color: var(--color-text-normal);
}

.listboxOptions {
    position: absolute;
    min-width: 150px;
    margin-top: 10px;
    padding: 4px 0px;
    background: var(--color-control);
    border: 1px solid var(--color-control-selected);
    border-radius: 4px;
}

.listboxOptions li {
    list-style-type: none;
    margin: 0;
    padding: 2px 10px;
    cursor: pointer;
}

.listboxOptions li:hover {
    background-color: var(--color-control-selected);
}

.buttonChevron {
    float: right;
    color: var(--color-text-muted);
    margin-left: 8px;
}

.takeWholeCell {
    width: 100%;
    height: 100%;
}